import {FORM_ELEM_HEIGHT} from 'helper/constants'

const materialStyle = (theme) => ({
  flexGrow: {
    flexGrow: 1,
  },

  noData: {
    color: theme.palette.text.secondary,
  },

  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
  },

  alignSelfFlexTop: {
    display: 'flex',
    alignSelf: 'flex-start !important',
    '& .MuiGrid-item': {
      display: 'flex',
      alignSelf: 'flex-start !important',
    },
  },

  // remove bottom line under last row in table
  tableRow: {
    transition: 'background-color 200ms',
    '&:last-child': {
      '& .MuiTableCell-body': {
        borderBottom: 'none',
      },
    },
    '&:hover': {
      '& .MuiTableCell-body': {
        backgroundColor: theme.palette.primary.main + '20',
      },
    },
  },

  tableRowHover: {
    '& .MuiTableCell-body': {
      backgroundColor: theme.palette.primary.main + '20',
    },
  },

  tableClass: {
    backgroundColor: theme.palette.white,
  },

  tableCellClass: {
    transition: 'background-color 200ms',
    padding: theme.spacing(2),
    verticalAlign: 'initial',
    whiteSpace: 'wrap',
    width: 'auto',
    lineHeight: 1.4,
    borderRight: ' 1px solid rgba(224, 224, 224, 1) !important',

    '&:last-child': {
      borderRight: '0px solid ' + theme.palette.text.hint + ' !important',
    },

    '& .MuiTableSortLabel-icon': {
      width: '16px',
      height: '16px',
      margin: '0 4px',
    },
  },

  activeOrderHeaderBackground: {
    backgroundColor: theme.palette.warning.light,
  },

  tableLabel: {
    cursor: 'default',
  },

  tableRowCursorPointer: {
    cursor: 'pointer',
  },

  tableCellDarkClass: {
    backgroundColor: theme.palette.loginBoxBgColor,
  },

  tableCellCheckboxClass: {
    borderRadius: 0,
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'flex-start',

    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },

  tableCellCheckboxTransition: {
    transition: 'background-color 200ms',
  },

  tableHeadCellClass: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    borderBottom: '2px solid ' + theme.palette.text.primary + ' !important',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: 'border-box',
  },

  tableHeadBorderBottom: {
    borderBottom: '2px solid ' + theme.palette.text.primary + ' !important',
  },

  tableBorderRight: {
    borderRight: ' 1px solid rgba(224, 224, 224, 1) !important',
  },

  dialogTitle: {
    margin: 0,
    padding: theme.spacing(3),
    paddingRight: '56px',
    borderBottom: '1px solid ' + theme.palette.border,
  },

  dialogContent: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.white,
  },

  dialogActions: {
    borderTop: '1px solid ' + theme.palette.border,
    padding: theme.spacing(3),
  },

  dialogCloseButton: {
    position: 'absolute',
    boxSizing: 'border-box',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,

    '& .MuiSvgIcon-root': {
      height: 24,
      width: 24,
      boxSizing: 'border-box',
    },
  },

  loader: {
    border: '5px solid transparent',
    borderRadius: '50%',
    borderTop: '5px solid ' + theme.palette.primary.main + '90',
    width: 40,
    height: 40,
    animation: '$spin 1.2s linear infinite',
    margin: '0 auto',
    padding: 20,
  },

  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'},
  },
  loaderPadding: {
    padding: 20,
  },
  loaderWrapper: {
    minHeight: '25vh',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
  },

  passwordEndAdornmentPadding: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 6,
    },
  },

  // password field end eye adornment
  passwordEndAdornment: {
    backgroundColor: theme.palette.white,

    '& .MuiIconButton-root': {
      padding: 4,

      '& .MuiSvgIcon-root': {
        width: 18,
        height: 18,
      },
    },
  },

  buttonRoot: {
    padding: '6px 12px',
    height: FORM_ELEM_HEIGHT,
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    border: '1px solid ' + theme.palette.primary.dark,
    boxShadow: 'none',

    '&.MuiButton-containedSizeSmall': {
      fontSize: 13,
    },
    '&.MuiButton-outlinedSizeSmall': {
      fontSize: 13,
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },

  outlinedButton: {
    backgroundColor: theme.palette.white,
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
  },

  tableButton: {
    fontSize: 12,
    minWidth: FORM_ELEM_HEIGHT,
    padding: 0,
    height: FORM_ELEM_HEIGHT,
    boxSizing: 'border-box',
    border: '1px solid ' + theme.palette.borderDark,
    backgroundColor: theme.palette.background.paper,
    transition: 'background-color 200ms',
    boxShadow: 'none',

    '& span': {
      height: '100%',
      width: 'auto',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',

      '& div': {
        height: '100%',
        width: 'max-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    '&:hover': {
      boxShadow: 'none',
    },

    '& svg': {
      fill: theme.palette.text.primary,
      height: 18,
      width: 18,
    },
  },

  disabledButton: {
    cursor: 'not-allowed !important',
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: '#e3e3e3 !important',
      color: theme.palette.text.disabled + '!important',
      borderColor: theme.palette.text.disabled + '!important',

      '& svg': {
        fill: theme.palette.text.disabled + '!important',
      },
    },
  },

  activeButton: {
    boxShadow: 'inset 0 3px 5px rgb(0 0 0 / 15%)',
    backgroundColor: theme.palette.loginBoxBgColor,
    transition: 'background-color 200ms',
    zIndex: 1,
    borderColor: theme.palette.borderDark,

    '&.MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)': {
      borderRightColor: theme.palette.borderDark,
    },
  },

  additionalButtonPadding: {
    padding: '0 12px !important',
  },

  customButtonGroup: {
    '& .MuiButtonGroup-grouped': {
      minWidth: 0,
    },
  },

  // custom card styles
  customCard: {
    width: '100%',
    border: '1px solid ' + theme.palette.borderDark,
  },

  customCardHeader: {
    borderBottom: '1px solid ' + theme.palette.borderDark,
    fontSize: 16,
    minHeight: 50,
    height: 50,
    padding: '0 16px 0 16px',
    display: 'flex',
    boxSizing: 'border-box',
  },

  customCardContent: {
    backgroundColor: theme.palette.white,
    '&.MuiCardContent-root:last-child': {
      paddingBottom: 16,
    },
  },

  rootPagination: {
    marginTop: 5,
    fontSize: '14px !important',

    '& .MuiIconButton-root': {
      padding: '4px',
      margin: '0px 4px',
    },

    '& .MuiSelect-icon': {
      top: 'calc(50% - 14px)',
    },

    '& .MuiSvgIcon-root': {
      fill: theme.palette.text.secondary,
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: 40,
    },
  },

  // icon in warning / confirmation dialog
  warningDialogTitleIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px 36px 12px',
  },
  warningDialogTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  warningDialogIcon: {
    fill: theme.palette.warning.main,
    height: 40,
    width: 40,
    paddingRight: theme.spacing(3),
  },
  warningDialogTitlePadding: {
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
  },

  checkBoxInput: {
    '& .MuiFormControlLabel-root': {
      color: theme.palette.text.primary,
      textTransform: 'none',
      fontSize: 14,
      margin: 0,
      lineHeight: 1.2,
    },
    '& .MuiFormControlLabel-labelPlacementTop': {
      alignItems: 'flex-start',
    },
    '& .MuiIconButton-root': {
      padding: 6,
      '& .MuiSvgIcon-root': {
        width: 22,
        height: 22,
      },
    },
  },

  // COLOR BUTTONS
  greenButtonColor: {
    backgroundColor: theme.palette.success.main + 'cc !important',
    border: '1px solid ' + theme.palette.success.main + ' !important',
    color: theme.palette.white + ' !important',
    '& svg': {
      fill: theme.palette.white + ' !important',
    },
    '&:hover': {
      transition: 'background-color 200ms',
      backgroundColor: theme.palette.success.main + ' !important',
    },
  },
  redButtonColor: {
    backgroundColor: theme.palette.error.main + 'cc !important',
    border: '1px solid ' + theme.palette.error.main + ' !important',
    color: theme.palette.white + ' !important',

    '& svg': {
      fill: theme.palette.white + ' !important',
    },
    '&:hover': {
      transition: 'background-color 200ms',
      backgroundColor: theme.palette.error.main + ' !important',
    },
  },
  orangeButtonColor: {
    backgroundColor: theme.palette.warning.main + 'cc !important',
    border: '1px solid ' + theme.palette.warning.main + ' !important',
    color: theme.palette.white + ' !important',

    '& svg': {
      fill: theme.palette.white + ' !important',
    },
    '&:hover': {
      transition: 'background-color 200ms',
      backgroundColor: theme.palette.warning.main + ' !important',
    },
  },
  blueButtonColor: {
    backgroundColor: theme.palette.primary.main + 'cc !important',
    border: '1px solid ' + theme.palette.primary.main + ' !important',
    color: theme.palette.white + ' !important',

    '& svg': {
      fill: theme.palette.white + ' !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main + ' !important',
      transition: 'background-color 200ms',
    },
  },
  blueOutlinedButtonColor: {
    backgroundColor: theme.palette.white + ' !important',
    border: '1px solid ' + theme.palette.primary.main + ' !important',
    color: theme.palette.primary.main + ' !important',

    '& svg': {
      fill: theme.palette.primary.main + ' !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.white + ' !important',
    },
  },
  redOutlinedButtonColor: {
    backgroundColor: theme.palette.white + ' !important',
    border: '1px solid ' + theme.palette.error.main + ' !important',
    color: theme.palette.error.main + ' !important',

    '& svg': {
      fill: theme.palette.error.main + ' !important',
    },
    '&:hover': {
      backgroundColor: theme.palette.white + ' !important',
    },
  },
  defaultButtonColor: {
    '&:hover': {
      borderColor: theme.palette.text.hint,
      boxShadow: 'inset 0 3px 5px rgb(0 0 0 / 15%)',
      backgroundColor: theme.palette.loginBoxBgColor,
      transition: 'background-color 200ms',
      zIndex: 1,
    },
  },

  linkText: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.secondary.main,

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  // error || helper text color
  formHelperText: {
    color: theme.palette.error.main,
    margin: '2px 8px',
    fontSize: '12px',
    width: '100%',
  },

  loadNextButtonFont: {
    fontSize: 14,
  },

  activeEfficiencyTableRow: {
    outlineOffset: '-2px !important',
    outline: '2px solid ' + theme.palette.primary.main + ' !important',
  },
})

export default materialStyle
