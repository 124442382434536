import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import moment from 'moment'
import React from 'react'
import {
  GET_LIST_PRICE_FAIL,
  GET_LIST_PRICE_LIST_FAIL,
  GET_LIST_PRICE_LIST_REQUEST,
  GET_LIST_PRICE_LIST_SUCCESS,
  GET_LIST_PRICE_REQUEST,
  GET_LIST_PRICE_SUCCESS,
  GET_PRICE_FAIL,
  GET_PRICE_LIST_FAIL,
  GET_PRICE_LIST_REQUEST,
  GET_PRICE_LIST_SUCCESS,
  GET_PRICE_REQUEST,
  GET_PRICE_SUCCESS,
  GET_PRICE_VERSIONS_FAIL,
  GET_PRICE_VERSIONS_REQUEST,
  GET_PRICE_VERSIONS_SUCCESS,
  GET_PRICE_VERSION_DIFF_FAIL,
  GET_PRICE_VERSION_DIFF_REQUEST,
  GET_PRICE_VERSION_DIFF_SUCCESS,
  UPDATE_GLOBAL_LOADING,
} from 'redux/actionType'
import {Trans} from '@lingui/macro'

export const getPriceLists = (limit, offset, meta, deleted, loadNext = false) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_PRICE_LIST_REQUEST, loadNext: loadNext})
    return httpClient
      .get(endpoints.priceList, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        show_deleted: deleted || null,
      })
      .then((res) => {
        dispatch({type: GET_LIST_PRICE_LIST_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_PRICE_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const searchPriceLists = (
  limit,
  offset,
  deleted,
  search,
  ids,
  tableSort,
  loadNext = false
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_PRICE_LIST_REQUEST, loadNext: loadNext})

    let urlParams = `?limit=${limit}&offset=${offset}`
    if (deleted) urlParams += `&show_deleted=${deleted}`
    if (ids?.length) urlParams += `&ids=${ids.join(',')}`
    if (tableSort?.orderBy && tableSort?.orderDirection)
      urlParams += `&order_by=${tableSort?.orderBy}:${tableSort?.orderDirection}`

    return httpClient
      .post(
        endpoints.priceList + '/search' + urlParams,
        {query: search || null},
        {headers: {tz: moment()?.format('Z')}}
      )
      .then((res) => {
        dispatch({type: GET_LIST_PRICE_LIST_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_PRICE_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const partialSearchPriceLists = (
  limit,
  offset,
  deleted,
  search,
  ids,
  tableSort,
  loadNext = false
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_PRICE_LIST_REQUEST, loadNext: loadNext})

    let urlParams = `?limit=${limit}&offset=${offset}`
    if (deleted) urlParams += `&show_deleted=${deleted}`
    if (ids?.length) urlParams += `&ids=${ids.join(',')}`
    if (tableSort?.orderBy && tableSort?.orderDirection)
      urlParams += `&order_by=${tableSort?.orderBy}:${tableSort?.orderDirection}`

    return httpClient
      .post(endpoints.priceList + '/partial-search' + urlParams, search, {
        headers: {tz: moment()?.format('Z')},
      })
      .then((res) => {
        dispatch({type: GET_LIST_PRICE_LIST_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_PRICE_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getPriceList = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_PRICE_LIST_REQUEST})
    return httpClient
      .get(endpoints.priceList + '/' + id)
      .then((res) => {
        dispatch({type: GET_PRICE_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PRICE_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getPrices = (id, limit, offset, meta, deleted, loadNext = false) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_PRICE_REQUEST, loadNext: loadNext})
    return httpClient
      .get(`${endpoints.priceList}/${id}/prices`, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        show_deleted: deleted || null,
      })
      .then((res) => {
        dispatch({type: GET_LIST_PRICE_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_PRICE_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const searchPrices = (id, limit, offset, deleted, search, loadNext = false) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_PRICE_REQUEST, loadNext: loadNext})

    let urlParams = `?limit=${limit}&offset=${offset}`
    if (deleted) urlParams += `&show_deleted=${deleted}`

    return httpClient
      .post(endpoints.priceList + '/' + id + '/prices/search' + urlParams, {
        query: search || null,
      })
      .then((res) => {
        dispatch({type: GET_LIST_PRICE_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_PRICE_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const partialSearchPrices = (id, limit, offset, deleted, search, loadNext = false) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_PRICE_REQUEST, loadNext: loadNext})

    let urlParams = `?limit=${limit}&offset=${offset}`
    if (deleted) urlParams += `&show_deleted=${deleted}`

    return httpClient
      .post(endpoints.priceList + '/' + id + '/prices/partial-search' + urlParams, search, {
        headers: {tz: moment()?.format('Z')},
      })
      .then((res) => {
        dispatch({type: GET_LIST_PRICE_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_PRICE_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getPrice = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_PRICE_REQUEST})
    return httpClient
      .get(endpoints.prices + '/' + id)
      .then((res) => {
        dispatch({type: GET_PRICE_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PRICE_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const createPriceList = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.priceList, data)
      .then((res) => {
        fireSuccessToast(<Trans>New price list created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const createPrice = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.priceList + '/' + id + '/prices', data)
      .then((res) => {
        fireSuccessToast(<Trans>New price created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deletePriceList = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(endpoints.priceList + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Price list deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deletePrice = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(endpoints.prices + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Price deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchPrice = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.prices + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Price updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchPriceList = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.priceList + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Price list updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const duplicatePriceList = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.priceList + '/' + id + '/duplicate', data)
      .then((res) => {
        fireSuccessToast(<Trans>New price list created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const copyPrices = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.priceList + '/' + id + '/transfer', data)
      .then((res) => {
        fireSuccessToast(<Trans>Prices copied.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

// ----------------------------------------------------------------------
// History
// ----------------------------------------------------------------------

export const getPriceVersions = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_PRICE_VERSIONS_REQUEST})
    return httpClient
      .get(`${endpoints.prices}/${id}/versions`, {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_PRICE_VERSIONS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PRICE_VERSIONS_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getPriceVersionsDiff = (id, versionId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_PRICE_VERSION_DIFF_REQUEST})
    return httpClient
      .get(`${endpoints.prices}/${id}/versions/${versionId}/diff`)
      .then((res) => {
        dispatch({type: GET_PRICE_VERSION_DIFF_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PRICE_VERSION_DIFF_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getPriceVersionsRollback = (id, versionId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(`${endpoints.prices}/${id}/versions/${versionId}/rollback`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
